<template>
  <div class="app-main__inner studyCenter">
      <div class="row">
          <div class="col-md-12 col-sm-12 fullWidth bottomMargin">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="fa fa-university icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>Learning Hub
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-container">
        <div class="row">
          <div class="col-lg-6 col-md-12 fullWidth">
            <div class="main-card mb-3">
              <div class="card" style="min-height:230px">
                <div class="card-header text-white bg-danger">
                  AI Study Tips
                </div>
                <div class="card-body">
                  <div class="box-part text-center">
                  <i class="pe-7s-notebook icon-gradient bg-tempting-azure fa-3x" aria-hidden="true"></i>
                  <div class="title">
                    <h4>Study Tips</h4>
                  </div>
                  <div class="text">
                    <span>Focus on the important questions: Speaking RA, RS,...</span>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted float-right">
                  <div class="btn-actions-pane-right">
                    <button v-b-modal="'AIStudyModal'" type="button" class="mb-2 mr-2 btn-transition btn btn-outline-info">View All</button>
                  </div>
                </div>
            </div>
          </div>
          </div>
          <div class="col-lg-6 col-md-12 fullWidth">
            <div class="main-card mb-3">
              <div class="card" style="min-height:230px">
                <div class="card-header text-white bg-danger">
                  Target
                  <span style="text-transform: capitalize;float: right;" v-if="target.exam_dte!='' & target.exam_dte!=null">Test Date: {{changeDate(target.exam_dte,'fullDate')}}</span>
                </div>
                <div class="card-body study_center_target">
                  <ul>
                    <li>
                      <h1 class="active">{{target.overAll}}</h1>
                      <span>Overall</span>
                    </li>
                    <li>
                      <h1>{{target.listening}}</h1>
                      <span>Listening</span>
                    </li>
                    <li>
                      <h1>{{target.reading}}</h1>
                      <span>Reading</span>
                    </li>
                    <li>
                      <h1>{{target.speaking}}</h1>
                      <span>Speaking</span>
                    </li>
                    <li>
                      <h1>{{target.writing}}</h1>
                      <span>Writing</span>
                    </li>
                  </ul>
                </div>
                <div class="card-footer text-muted float-right">
                  <!-- <div class="btn-actions-pane-left">
                    <span class="mb-2 mr-2 btn-transition btn btn-outline-info">15 FEB 2021</span>
                  </div> -->
                  <div class="btn-actions-pane-right">
                    <button type="button" class="mb-2 mr-2 btn-transition btn btn-outline-info" @click="showmodal('target')">Set Target</button>
                  </div>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 d-none d-lg-block">
            <div class="main-card mb-3">
                <div class="card">
                  <div class="card-header text-white bg-danger">
                    Exam Histories
                    <b-button v-if="showExamBtn" style="float: right;
    margin-top: -3px;
    padding: 2px 8px;
    font-weight: 600!important;" variant="warning" @click="showmodal('exam')">Exam Score</b-button>
                  </div>
                  <!-- exam_histories -->
                  <div class="card-body tabExam-History">
                    <b-tabs content-class="mt-3 text-center"
                    active-nav-item-class="font-weight-bold text-uppercase text-danger"
                    active-tab-class="font-weight-bold text-success" title-item-class="text-center"
                    justified fill end>
                    <b-tab title="Attempted Exam" active @click="tabClicked('exam')">
                      <div class="row d-flex justify-content-around">
                        <div class="col-2" v-for="(option, index) in chartData" :key="index">
                          <apexchart :width="option.width" type="radialBar" :options="option.options" :series="option.seriesExam"></apexchart>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="First Mock" @click="tabClicked('firstMock')">
                      <div class="row d-flex justify-content-around" >
                        <div class="col-2" v-for="(option, index) in chartData" :key="index">
                          <apexchart :width="option.width" type="radialBar" :options="option.options" :series="option.seriesMock1"></apexchart>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Second Mock" @click="tabClicked('secondMock')">
                      <div class="row d-flex justify-content-around">
                        <div class="col-2" v-for="(option, index) in chartData" :key="index">
                          <apexchart :width="option.width" type="radialBar" :options="option.options" :series="option.seriesMock2"></apexchart>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Third Mock" @click="tabClicked('thirdMock')">
                      <div class="row d-flex justify-content-around">
                        <div class="col-2" v-for="(option, index) in chartData" :key="index">
                          <apexchart :width="option.width" type="radialBar" :options="option.options" :series="option.seriesMock3"></apexchart>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                    
                  </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 fullWidth">

            <div class="main-card mb-3">
                <div class="card">
                  <div class="card-header text-white bg-danger">
                    History Records
                  </div>
                  <div class="card-body">
                    <div class="accordion md-accordion historyRecord" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <Collaps :type="'practice'"/>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal no-close-on-backdrop no-close-on-esc centered id="setTarget" ref="setTarget" :title="modalData.title">
    <template>
      <div class="row">
        <div class="col-md-12 mb-1 col-sm-12">
          <b-form-group
            label="OverAll"
            label-for="input-overAll"
            class="mb-0">
            <b-form-input
              id="input-overAll"
              pattern="[0-9]"
              v-model="modalData.overAll"
              v-validate="'required|max_value:90|min_value:1'"
              placeholder="OverAll"
              v-on:keypress="isNumber"
              type="number" min="1" step="1" max="90"
              name="OverAll"
              :class="{'input': true, 'is-danger': errors.has('OverAll') }"
            ></b-form-input>
            <span v-show="errors.has('OverAll')" class="help is-danger">Overall value must be number and less than 90</span>

        </b-form-group>
        <!-- <b-form-input type="number" v-model="target.overAll" id="swal-input1" class="" placeholder="Overall"></b-form-input> -->
        </div>
        <div class="col-md-12 mb-1 col-sm-12">
          <b-form-group
            label="Listening"
            label-for="input-listening"
            class="mb-0">
            <b-form-input
              id="input-listening"
              v-model="modalData.listening"
              name="listening"
              type="number"
              v-validate="'required|max_value:90|min_value:1'"
              v-on:keypress="isNumber"
              placeholder="Listening"
              min="1" max="90"
              :class="{'input': true, 'is-danger': errors.has('listening') }"
            >
            </b-form-input>
            <span v-show="errors.has('listening')" class="help is-danger">Listening value must be number and less than 90</span>

        </b-form-group>
        </div>
        <div class="col-md-12 mb-1 col-sm-12">
          <b-form-group
            label="Reading"
            label-for="input-reading"
            class="mb-0">
            <b-form-input
              id="input-reading"
              v-model="modalData.reading"
              v-validate="'required|max_value:90|min_value:1'"
              type="number"
              v-on:keypress="isNumber"
              placeholder="Reading"
              min="1" max="90"
            name="reading"
              :class="{'input': true, 'is-danger': errors.has('reading') }"
            ></b-form-input>
            <span v-show="errors.has('reading')" class="help is-danger">Reading value must be number and less than 90</span>
        </b-form-group>
        </div>
        <div class="col-md-12 col-sm-12 mb-1">
          <b-form-group
            label="Speaking"
            label-for="input-speaking"
            class="mb-0">
            <b-form-input
              id="input-speaking"
              v-model="modalData.speaking"
              v-validate="'required|max_value:90|min_value:1'"
              type="number"
              v-on:keypress="isNumber"
              placeholder="Speaking"
              min="1" max="90"
            name="speaking"
              :class="{'input': true, 'is-danger': errors.has('speaking') }"
            ></b-form-input>
            <span v-show="errors.has('speaking')" class="help is-danger">Speaking value must be number and less than 90</span>
        </b-form-group>
        </div>
        <div class="col-md-12 col-sm-12 mb-1">
          <b-form-group
            label="Writing"
            label-for="input-writing"
            class="mb-0">
            <b-form-input
              id="input-writing"
              v-model="modalData.writing"
              v-validate="'required|max_value:90|min_value:1'"
              type="number"
              v-on:keypress="isNumber"
              placeholder="Writing"
              min="1" max="90"
             name="writing"
              :class="{'input': true, 'is-danger': errors.has('writing') }"
            ></b-form-input>
            <span v-show="errors.has('writing')" class="help is-danger">Writing value must be number and less than 90</span>
        </b-form-group>
        </div>
        <div class="col-md-12 col-sm-12">
          <b-form-group
            label="Exam Date"
            label-for="input-exam_dte"
            class="mb-0 label-datepicker"
            >
          <date-picker v-model="modalData.exam_dte" value-type="format" format="YYYY-MM-DD"></date-picker>

            <!-- <b-form-datepicker
              id="input-exam_dte"
              v-model="modalData.exam_dte"
              placeholder="Exam Date"
              :min="minDate"
              name="exam-date"
               v-validate="'required'"
               :class="{'input': true, 'is-danger': errors.has('exam-date') }"
            ></b-form-datepicker> -->
            <span v-show="errors.has('writing')" class="help is-danger">Exam date is required</span>
        </b-form-group>
        </div>
        <div class="col-md-12 col-sm-12" v-if="modalType=='exam'">
          <b-form-group
            label="Exam Report"
            label-for="input-report"
            class="mb-0">
            <b-form-file
              id="input-report"
              v-model="modalData.result_card_path"
              placeholder="Exam Report"
              drop-placeholder="Drop file here..."
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              @change="onChange"
            ></b-form-file>
        </b-form-group>
        </div>
      </div>
    </template>

    <template  #modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button class="left" size="md" variant="outline-warning" @click="resetAll()">
        Reset
      </b-button>
      <b-button size="md" variant="outline-success" @click="saveTarget()">
        Save
      </b-button>
      <b-button size="md" variant="outline-danger" @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
  <b-modal size="xl" hide-footer ref="AIStudyModal" id="AIStudyModal" scrollable title="AI Study Guideline">
   <AISTudies/>
  </b-modal>
  </div>
</template>
<script>
import apexchart from 'vue-apexcharts'
import Swal from 'sweetalert2'
import Collaps from '../reuseable/collaps' 
import AISTudies from './AIStudyTips'
import { mapActions,mapGetters } from 'vuex'
import moment from "moment";
import $ from 'jquery'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  components: {
    apexchart,
    Collaps,
    AISTudies,
    DatePicker
  },
    data () {
    return {
      modalData:{
        reading:0,
        writing:0,
        listening:0,
        speaking:0,
        overAll:0,
        exam_dte:'',
        user_id:0,
        result_card_path:'',
        url:''
      },
      showExamBtn:true,
      minDate:'',
      tempModel:{},
      loadedFile:null,
      target:{
        title:'Set Target Score',
        reading:0,
        writing:0,
        listening:0,
        speaking:0,
        overAll:0,
        exam_dte:'',
        user_id:0,
        result_card_path:'',
        url:'configtarger/createtarget'
      },
      exam:{
        title:'Add Exam Score',
        reading:0,
        writing:0,
        listening:0,
        speaking:0,
        overAll:0,
        exam_dte:'',
        user_id:0,
        result_card_path:'',
        url:'configexamScore/createExamScore'
      },
      modalType:'target',
      chartData: [
        {
          options: {
            chart: {
              height: 220,
              type: 'radialBar'
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: '90%',
                  background: '#293450'
                },
                track: {
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15
                  }
                },
                dataLabels: {
                  name: {
                    offsetY: 0,
                    color: '#fff',
                    fontSize: '13px'
                  },
                  value: {
                    color: '#fff',
                    fontSize: '20px',
                    offsetY: 8,
                    show: true
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                gradientToColors: ['#66ff33'],
                stops: [0, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['Overall']
          },
          seriesExam: [0],
          seriesMock1: [0],
          seriesMock2: [0],
          seriesMock3: [0],
          colors: ['#66ff33'],
          width: 160
        },
        {
          options: {
            chart: {
              height: 180,
              type: 'radialBar'
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#293450'
                },
                track: {
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15
                  }
                },
                dataLabels: {
                  name: {
                    offsetY: 0,
                    color: '#fff',
                    fontSize: '11px'
                  },
                  value: {
                    color: '#fff',
                    fontSize: '15px',
                    show: true,
                    offsetY: 4,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                gradientToColors: ['#ff00ff'],
                stops: [0, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['Listening']
          },
          width: 150,
          seriesExam: [0],
          seriesMock1: [0],
          seriesMock2: [0],
          seriesMock3: [0],
          colors: ['#20E647']
        },
        {
          options: {
            chart: {
              height: 180,
              type: 'radialBar'
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#293450'
                },
                track: {
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15
                  }
                },
                dataLabels: {
                  name: {
                    offsetY: 0,
                    color: '#fff',
                    fontSize: '11px'
                  },
                  value: {
                    color: '#fff',
                    fontSize: '15px',
                    show: true,
                    offsetY: 4,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                gradientToColors: ['#00ffcc'],
                stops: [0, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['Reading']
          },
          seriesExam: [0],
          seriesMock1: [0],
          seriesMock2: [0],
          seriesMock3: [0],
          width: 150,
          colors: ['#20E647']
        },
        {
          options: {
            chart: {
              height: 180,
              type: 'radialBar'
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#293450'
                },
                track: {
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15
                  }
                },
                dataLabels: {
                  name: {
                    offsetY: 0,
                    color: '#fff',
                    fontSize: '11px'
                  },
                  value: {
                    color: '#fff',
                    fontSize: '15px',
                    show: true,
                    offsetY: 4,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                gradientToColors: ['#9933ff'],
                stops: [0, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['Speaking']
          },
          seriesExam: [0],
          seriesMock1: [0],
          seriesMock2: [0],
          seriesMock3: [0],
          width: 150,
          colors: ['#20E647']
        },
        {
          options: {
            chart: {
              height: 180,
              type: 'radialBar'
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: '70%',
                  background: '#293450'
                },
                track: {
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15
                  }
                },
                dataLabels: {
                  name: {
                    offsetY: 0,
                    color: '#fff',
                    fontSize: '11px'
                  },
                  value: {
                    color: '#fff',
                    fontSize: '15px',
                    show: true,
                    offsetY: 4,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'vertical',
                gradientToColors: ['#ff0066'],
                stops: [0, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['Writing']
          },
          seriesExam: [0],
          seriesMock1: [0],
          seriesMock2: [0],
          seriesMock3: [0],
          width: 150,
          colors: ['#ffff00']
        }
      ]
    }
  },
  created () {
    this.Toast = Swal.mixin({
      toast: false,
      position: 'center',
      showConfirmButton: true,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

  },
  watch:{
    'modalData.listening': {
        handler(val){
          this.modalData.listening=parseInt(val,10)},
        deep: true},
    'modalData.reading': {
        handler(val){this.modalData.reading=parseInt(val,10)},
        deep: true},
    'modalData.writing': {
        handler(val){this.modalData.writing=parseInt(val,10)},
        deep: true},
    'modalData.speaking': {
        handler(val){this.modalData.speaking=parseInt(val,10)},
        deep: true}
  },
  beforeMount(){
    this.target.user_id=this.$ls.get('user').user_id
    this.exam.user_id=this.target.user_id
    this.fetchTarget()
    this.fetchExam()
    this.mockResults()
  },
  methods: {
    ...mapActions({
            fetchQuestionPractice:'auth/fetchGraphData',
            SaveDashBoard:'auth/saveDashBoardData',
            fileUpload:'speakmodule/uploadFile',
            questionservice: 'mocktest/fetchQuestion',
        }),
    ...mapGetters({
        GetQuestionPractice:'auth/getPracticeNumber',
        getQuestionList: 'mocktest/questions',
        GetDashBoardResponse:'auth/getDashBoardResponse'
    }),
    tabClicked(selectedTab){
      if(selectedTab=='exam'){
        this.showExamBtn=true
      }
      else{
        this.showExamBtn=false
      }
    },
    resetAll(){
      this.modalData.reading=0
      this.modalData.writing=0
      this.modalData.listening=0
      this.modalData.speaking=0
      this.modalData.overAll=0
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      this.modalData.exam_dte=this.changeDate(new Date(today))
    },
    setMinMaxDate(){
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      // 15th two months prior
      this.minDate = new Date(today)
      this.minDate.setMonth(this.minDate.getMonth())
      if(this.modalType=='exam'){
        this.minDate.setMonth(this.minDate.getMonth()-24) //2 years back
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
      var key = evt.keyCode || evt.which;
      key = String.fromCharCode(key);
      //console.log(key)
      return true;
      }
    },
    showModal () {
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    },
    cancel(){
      this.$refs.setTarget.hide();
    },
    changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("Do MMMM YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("YYYY-MM-DD");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
    onChange(e) { 
            this.loadedFile = e.target.files
      },
    fetchTarget(){
      const url='configtarger/id='+this.target.user_id
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                if(response!=null){
                  if(response.success==1 & response.data!=null){
                      //console.log(response.data)
                      ////debugger
                      this.target.overAll=response.data.overall
                      this.target.speaking=response.data.speaking
                      this.target.reading=response.data.reading
                      this.target.writing=response.data.writing
                      this.target.listening=response.data.listening
                      this.target.exam_dte=response.data.exam_dte
                  }
                }
            })
    },
    fetchExam(){
      const url='configexamScore/id='+this.exam.user_id
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                if(response!=null){
                  if(response.success==1 & response.data!=null){
                      //console.log(response.data)
                      this.exam.overAll=response.data.overall
                      this.exam.speaking=response.data.speaking
                      this.exam.reading=response.data.reading
                      this.exam.writing=response.data.writing
                      this.exam.listening=response.data.listening
                      this.exam.exam_dte=response.data.exam_dte
                  }
                      this.updateChartData()
                  
                }
            })
    },
    updateChartData(){
      this.chartData[0].seriesExam=[this.exam.overAll]
      this.chartData[1].seriesExam=[this.exam.listening]
      this.chartData[2].seriesExam=[this.exam.reading]
      this.chartData[3].seriesExam=[this.exam.speaking]
      this.chartData[4].seriesExam=[this.exam.writing]
    },
    showmodal(modalType){
      this.modalType=modalType
      this.setMinMaxDate()
      this.tempModel={}
      if(this.modalType=='exam') {
        this.modalData.overAll=this.exam.overAll
        this.modalData.speaking=this.exam.speaking
        this.modalData.reading=this.exam.reading
        this.modalData.writing=this.exam.writing
        this.modalData.listening=this.exam.listening
        this.modalData.exam_dte=this.changeDate(this.exam.exam_dte,'shortDate')
        this.modalData.url=this.exam.url
        this.modalData.result_card_path=this.exam.result_card_path
        this.modalData.user_id=this.exam.user_id
        this.modalData.title=this.exam.title
      }
      else{
        this.modalData.overAll=this.target.overAll
        this.modalData.speaking=this.target.speaking
        this.modalData.reading=this.target.reading
        this.modalData.writing=this.target.writing
        this.modalData.listening=this.target.listening
        this.modalData.exam_dte=this.changeDate(this.target.exam_dte,'shortDate')
        this.modalData.url=this.target.url
        this.modalData.result_card_path=this.target.result_card_path
        this.modalData.user_id=this.target.user_id
        this.modalData.title=this.target.title
        //this.tempModel=this.target
      }
      this.$refs.setTarget.show();
    },
    saveTarget(){
      this.$validator.validateAll().then((result) => {
        if (result) {
          // eslint-disable-next-line
         var data={
                fileName:'ExamReport--',
                files:(this.loadedFile==null)?'':this.loadedFile
            }
          data.fileName+= this.modalData.user_id
       if(this.modalType=='exam') {
        this.exam=this.modalData
        this.modalData.result_card_path=data.fileName
        this.updateChartData()
      }
      else{
        this.target=this.modalData
      }
       
      if(this.modalData.speaking>90 | this.modalData.overAll>90 | this.modalData.reading>90 | this.modalData.listening>90 | this.modalData.writing>90){
       if(this.modalType=='exam'){this.fetchExam()}
       else {this.fetchTarget()}
       return this.$alert('Score must be below 90.')
      }
      else{ 
        this.modalData.exam_dte=this.changeDate(this.modalData.exam_dte,'shortDate')
        this.SaveDashBoard(this.modalData).then(()=>{
          const response=this.GetDashBoardResponse()
          if(this.modalType=='exam'){
            this.fileUpload(data)
          }
          this.$refs['setTarget'].hide()
          //console.log(response)
        })

      }
        }
        else{
          this.$alert('Correct errors!');
        }

        
      });
      
      
    },
    mockResults(){
      const userId=this.$ls.get('user').user_id
      let count=0
      var vm=this
      this.questionservice('mocktestresult/mockResult/userId='+userId).then(()=>{
          var response=this.getQuestionList().data
          $.each(response, function(){
            var item = JSON.parse(this.mock_result)
            //console.log(item)
            count++
            if(count==1){
                vm.chartData[0].seriesMock1=[item.overAll]
                vm.chartData[1].seriesMock1=[item.listening]
                vm.chartData[2].seriesMock1=[item.reading]
                vm.chartData[3].seriesMock1=[item.speaking]
                vm.chartData[4].seriesMock1=[item.writing]
            }
            else if(count ==2)
            {
                vm.chartData[0].seriesMock2=[item.overAll]
                vm.chartData[1].seriesMock2=[item.listening]
                vm.chartData[2].seriesMock2=[item.reading]
                vm.chartData[3].seriesMock2=[item.speaking]
                vm.chartData[4].seriesMock2=[item.writing]
            }
            else{
                vm.chartData[0].seriesMock3=[item.overAll]
                vm.chartData[1].seriesMock3=[item.listening]
                vm.chartData[2].seriesMock3=[item.reading]
                vm.chartData[3].seriesMock3=[item.speaking]
                vm.chartData[4].seriesMock3=[item.writing]
            }
          })
      })
    }
  },

}
</script>
<style>
.tabExam-History a.nav-link{
  display: block;
}
</style>
<style scoped>
.modal-footer.text-center
{
  margin: auto;
    background: transparent;
    border: 0px;
}
</style>
<style scoped>
.label-datepicker
{
    display: block!important;
}
.mx-datepicker
{
    width: 100%;
    border-color: #d0d6db;
}
</style>