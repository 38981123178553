<template>
    <div class="row">
        <div class="col-12 aistudytip">
            <div class="col-12 collaps">
                    <b-card class="mb-3"
                    border-variant="primary"
                    header="Tips Abstract"
                    header-bg-variant="primary"
                    header-text-variant="white">
                      <b-card-text>
                        <p>Focus on the important questions: Speaking RA, RS, DI, RL Writing SWT, WE Reading RO, FIB-R, FIB-RW Listening SST, FIB-L, HIW, WFD
                        Other non-important questions will not affect much of your scores.</p>
                        <p>(Upload your exam / mock score reports to get more customized suggestions.)</p>
                      </b-card-text>
                    </b-card>
                    <b-card class="mb-3"
                    border-variant="success"
                    header="Speaking"
                    header-bg-variant="success"
                    header-text-variant="white">
                      <b-card-text>
                        <p>Need to work on RA, RS, DI, RL. ASQ is not important since it won't affect yous scores much.</p>
                        <p>In order to achieve 84 in speaking, you need to have at least 69+ in pronunciation, and 84 in fluency.</p>
                        <p>When practicing in PTE Classes, the overall score should also be constantly above 80. But, scoring on some edge cases may not be accurate. If you have doubts about the scoring of your answers, it is best to consult PTE Classes tutor to get an accurate evaluation.</p>
                      </b-card-text>
                    </b-card>
                    <b-card  class="mb-3"
                    border-variant="info"
                    header="Writing"
                    header-bg-variant="info"
                    header-text-variant="white">
                      <b-card-text>
                        <p> Need to focus on SWT, WE. These are the easiest questions in PTE. Make good use of PTE Classes's templates, and pay attention to spelling and grammar details.
                        </p><p>
                            While practicing in PTE Classes, you should aim for at most 2 marks deduction of each answer.
                            </p>
                      </b-card-text>
                    </b-card>
                    <b-card class="mb-3"
                    border-variant="warning"
                    header="Reading"
                    header-bg-variant="warning"
                    header-text-variant="white">
                      <b-card-text>
                        <p>Need to build up your vocabulary to 9000+ words. Aim for at least 80% correctness in FIB-R, FIB-RW.</p>
                        <p>RO is one of the hardest questions in PTE, but luckily there are only 2 - 3 questions of it in exam. Thus it won't affect the overall reading score much. Suffice to have 50% correctness.</p>
                        <p>MCQ are not important. Plan well your time and leave enough time for FIB.</p>
                      </b-card-text>
                    </b-card>
                    <b-card class="mb-3"
                    border-variant="secondary"
                    header="Listening"
                    header-bg-variant="secondary"
                    header-text-variant="white">
                      <b-card-text>
                        <p>Need to focus on SST, FIB-L, HIW, WFD.</p>
                        <p>SST is actually an easy question. Make good use of PTE Classes's SST template. While practicing in PTE Classes, you should aim for at most 2 marks deduction of each SST answer.</p>
                        <p>HIW: aim for 100% correctness. It is one of the easiest questions in PTE.</p>
                        <p>FIB and WFD: aim for at least 80% correctness.</p>
                        <p>MCQ are not important. Plan well your time and leave enough time for WFD in the end.</p>
                      </b-card-text>
                    </b-card>
              </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style >
.aistudytip .card-header {
    padding: 0.85rem 1.25rem;
}
</style>